import React ,{useEffect} from 'react'
import Layout from '../componets/Layout'
import SimpanKeBlockchain from '../componets/SimpanKeBlockchain'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { getMe } from '../features/authSlice'

const Upload = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isError} = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getMe());
  }, [dispatch]);

  useEffect(() => {
    if(isError) {
      navigate("/");
    }
  }, [isError, navigate]);
  return (
  <Layout>
    <SimpanKeBlockchain/>
  </Layout>
  )
}

export default Upload;